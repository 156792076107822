import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Home - Thomas'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to set the page title
router.beforeEach((to, from, next) => {
  const defaultTitle = 'Thomas'; // Titre par défaut
  document.title = to.meta.title || defaultTitle;
  next();
})

export default router
