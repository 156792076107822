<template>
  <!-- Header placé en premier dans le template -->
  <header :class="headerClasses"
    class="fixed top-0 left-0 w-full z-50 transition-colors duration-500 bg-white dark:bg-gray-900">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex items-center gap-x-12">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">{{ t.company }}</span>
          <img class="h-8 w-auto" src="@/assets/Logotype-Thomas.png" alt="Logo" />
        </a>
        <div class="hidden lg:flex lg:gap-x-12">
          <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm leading-6"
            :class="textClasses">{{ item.name }}</a>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <!-- Gestion du thème -->
        <button @click="toggleTheme" class="p-2 rounded-lg">
          <SunIcon v-if="theme === 'dark'" class="h-6 w-6 text-gray-400" />
          <MoonIcon v-else class="h-6 w-6 text-gray-800" />
        </button>
        <!-- Sélection de la langue -->
        <Listbox as="div" v-model="selectedLanguage">
          <div class="relative">
            <ListboxButton
              class="relative w-48 cursor-pointer rounded-md py-2 pl-3 pr-10 text-left text-gray-900 dark:text-white sm:text-base sm:leading-6">
              <span class="flex items-center">
                <img :src="selectedLanguage.flag" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                <span class="ml-2 block truncate">{{ selectedLanguage.name }}</span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-4 w-4 text-gray-300" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
              leave-to-class="opacity-0">
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700">
                <ListboxOption as="template" v-for="(language, index) in languages" :key="language.code"
                  :value="language" v-slot="{ active, selected }">
                  <li :class="[
                    'relative cursor-pointer select-none py-2 pl-3 pr-9',
                    active ? 'bg-rose-400 text-white' : 'text-gray-900 dark:text-white',
                    selected ? 'text-white' : '',
                    index === 0 && selected ? 'rounded-t-md' : '',
                    index === languages.length - 1 && selected ? 'rounded-b-md' : ''
                  ]">
                    <div class="flex items-center">
                      <img :src="language.flag" alt="" class="h-4 w-4 flex-shrink-0 rounded-full" />
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-2 block truncate']">{{
                        language.name }}</span>
                    </div>
                    <span v-if="selected"
                      :class="[active ? 'text-white' : 'text-rose-500', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-4 w-4" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            :class="textClasses" @click="mobileMenuOpen = true">
            <span class="sr-only">{{ t.company }}</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </nav>

    <!-- Progress bar simple -->
    <div class="relative">
      <div class="absolute bottom-0 left-0 h-0.5 w-full bg-gradient-to-r from-rose-400 via-rose-500 to-rose-400"></div>
    </div>

    <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">{{ t.company }}</span>
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=rose&shade=600" alt="Logo" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in navigation" :key="item.name" :href="item.href"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{
                  item.name }}</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>

  <!-- Section 1 -->
  <div class="relative isolate pt-14 bg-white dark:bg-gray-900 transition-colors duration-500">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
        <div class="flex items-left justify-left">
          <div
            class="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 dark:text-gray-400 ring-1 ring-gray-300 dark:ring-gray-700">
            <span class="text-rose-500 rounded-xl">{{ t.weAreHiring }}</span>
            <span class="h-4 w-px bg-gray-900/10 dark:bg-gray-700" aria-hidden="true"></span>
            <!-- Cette partie déclenche maintenant l'ouverture de la modale -->
            <span @click="openModalDownloadCV = true" class="flex items-center gap-x-1 cursor-pointer">
              {{ t.seeOpenPositions }}
              <ChevronRightIcon class="-mr-2 h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />
            </span>
          </div>
        </div>
        <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
          {{ t.betterWay }} <span class="text-gradient carter-policy">{{ t.betterWayFinish }}</span>
        </h1>
        <p class="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">{{ t.description }}</p>
        <div class="mt-10 flex items-center gap-x-6">
          <a href="#"
            class="rounded-md bg-rose-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600">
            {{ t.getStarted }}
          </a>
        </div>
      </div>
      <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow hidden lg:block">
        <svg viewBox="0 0 366 729" role="img" class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
          <title>App screenshot</title>
          <defs>
            <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
              <rect width="316" height="684" rx="36" />
            </clipPath>
          </defs>
          <path fill="#4B5563"
            d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z" />
          <path fill="#343E4E"
            d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z" />
          <foreignObject width="316" height="684" transform="translate(24 24)"
            clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)">
            <img class="w-full h-full object-cover" src="@/assets/photo.jpg" alt="App screenshot" />
          </foreignObject>
        </svg>
      </div>
    </div>
  </div>

  <!-- Custom SVG Divider -->
  <div class="w-full m-0 p-0 leading-none border-0 transition-colors duration-500">
    <img v-show="theme === 'dark'" src="@/assets/curve-divider-dark.svg" alt="Custom divider"
      class="w-full h-auto transition-opacity duration-500 opacity-100" />
    <img v-show="theme === 'light'" src="@/assets/curve-divider-light.svg" alt="Custom divider"
      class="w-full h-auto transition-opacity duration-500 opacity-100" />
  </div>

  <!-- Section "Mes compétences" -->
  <div class="bg-gray-300 dark:bg-gray-800 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 text-center lg:px-8">
      <div class="mx-auto max-w-4xl">
        <h1 class="text-2xl tracking-tight lg:text-5xl text-gradient">{{ t.skillsTitle }}</h1>

        <p class="mt-4 text-lg leading-8 text-gray-800 dark:text-gray-400">{{ t.skillsDescription }}</p>
      </div>
      <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6 mt-12">
        <ul v-for="(person, index) in people" :key="person.name"
          class="flex flex-col rounded-2xl p-0.5 max-w-full">
          <div class="rounded-2xl px-6 py-8">
            <div class="flex justify-center items-center">
              <!-- Ajustement de la taille pour que le logo rentre bien dans le cadre -->
              <img class="h-16 w-16 md:h-20 md:w-20 object-contain" :src="person.imageUrl" alt="" />
            </div>
            <h3 class="mt-8 text-base leading-7 tracking-tight font-bold text-black dark:text-white">{{ person.name }}
            </h3>
            <p class="mt-4 text-sm leading-6 text-gray-500 dark:text-gray-400">{{ person.role }}</p>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <!-- Modale de maintenance -->
  <TransitionRoot as="template" :show="openModal">
    <Dialog class="relative z-10" @close="closeModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-85 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div class="text-center">
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                  <!-- Icône d'exclamation -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ t.maintenanceTitle }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ t.maintenanceDescription }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button"
                  class="outline-none inline-flex w-full justify-center items-center rounded-md bg-rose-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-rose-500"
                  @click="openModal = false">
                  D'accord !
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Modale de téléchargement de CV -->
  <TransitionRoot as="template" :show="openModalDownloadCV">
    <Dialog class="relative z-10" @close="openModalDownloadCV = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-85 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div class="text-center">
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                  <!-- Icône de téléchargement -->
                  <NewspaperIcon class="h-6 w-6 text-blue-600" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ t.downloadCVTitle }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ t.downloadCVDescription }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button"
                  class="outline-none inline-flex w-full justify-center items-center rounded-md bg-rose-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-rose-500"
                  @click="openModalDownloadCV = false">
                  Télécharger
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue'
import { SunIcon, MoonIcon, Bars3Icon, XMarkIcon, CheckIcon, NewspaperIcon } from '@heroicons/vue/24/outline'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Dialog, TransitionRoot, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/vue'

// Liste des langues avec leurs drapeaux
import flagFr from '@/assets/langage/flag_fr.png'
import flagEs from '@/assets/langage/flag_es.png'
import flagUk from '@/assets/langage/flag_uk.png'

const languages = [
  { code: 'fr', name: 'Français', flag: flagFr },
  { code: 'es', name: 'Español', flag: flagEs },
  { code: 'uk', name: 'English', flag: flagUk },
]

const selectedLanguage = ref(languages[0]) // Français par défaut

// Gestion du thème
const theme = ref('dark'); // Toujours sombre

function toggleTheme() {
  theme.value = theme.value === 'dark' ? 'light' : 'dark';
  document.documentElement.classList.toggle('dark', theme.value === 'dark');
  textClasses.value = theme.value === 'dark' ? 'text-white' : 'text-gray-900';
}

// Messages traduits
const messages = {
  fr: {
    company: 'Thomas',
    weAreHiring: 'Dernière mise à jour : v1.0',
    seeOpenPositions: 'Téléchargez mon CV',
    betterWay: 'Plongez dans mon univers',
    betterWayFinish: 'digital !',
    description:
      'Étudiant passionné et développeur web, je crée des solutions numériques innovantes tout en poursuivant mes études. Explorez mon parcours, mes projets, et rejoignez-moi dans cette aventure digitale.',
    getStarted: 'Commencer',
    skillsTitle: 'Connaissances et Compétences',
    skillsDescription: 'En tant qu\'étudiant et auto-entrepreneur, j\'ai acquis des compétences variées dans plusieurs technologies. J\'ai eu l\'opportunité de les mettre en pratique à la fois dans des projets académiques et dans des missions professionnelles, ce qui me permet d\'appliquer ces connaissances de manière concrète et de proposer des solutions adaptées aux besoins spécifiques.',
    maintenanceTitle: 'Site en développement',
    maintenanceDescription: 'Ce site est actuellement en cours de réalisation. Je travaille activement pour vous offrir une expérience de navigation optimale.',
    downloadCVTitle: 'Télécharger mon CV',
    downloadCVDescription: 'Cliquez sur le bouton ci-dessous pour télécharger mon CV. Je reste à votre disposition pour toute question ou pour explorer de potentielles opportunités de collaboration.',
    navigation: [
      { name: 'ACCUEIL', href: '#' },
      { name: 'PARCOURS', href: '#' },
      { name: 'RESSOURCES', href: '#' },
    ],
  },
  es: {
    company: 'Thomas',
    weAreHiring: 'Última actualización: v1.0',
    seeOpenPositions: 'Descarga mi CV',
    betterWay: 'Sumérgete en mi universo',
    betterWayFinish: 'digital !',
    description:
      'Estudiante apasionado y desarrollador web, creo soluciones digitales innovadoras mientras sigo con mis estudios. Explora mi trayectoria, mis proyectos, y únete a mí en esta aventura digital.',
    getStarted: 'Empezar',
    skillsTitle: 'Conocimientos y Habilidades',
    skillsDescription: 'Como estudiante y autónomo, he adquirido una variedad de habilidades en varias tecnologías. He tenido la oportunidad de ponerlas en práctica tanto en proyectos académicos como en misiones profesionales, lo que me permite aplicar estos conocimientos de manera concreta y ofrecer soluciones adaptadas a necesidades específicas.',
    maintenanceTitle: 'Sitio en desarrollo',
    maintenanceDescription: 'Este sitio está actualmente en desarrollo. Estoy trabajando activamente para ofrecerle una experiencia de navegación óptima.',
    downloadCVTitle: 'Descargar mi CV',
    downloadCVDescription: 'Haga clic en el botón de abajo para descargar mi CV. Estoy disponible para cualquier pregunta o para explorar posibles oportunidades de colaboración.',
    navigation: [
      { name: 'INICIO', href: '#' },
      { name: 'TRAYECTORIA', href: '#' },
      { name: 'RECURSOS', href: '#' },
    ],
  },
  uk: {
    company: 'Thomas',
    weAreHiring: 'Last update: v1.0',
    seeOpenPositions: 'Download my resume',
    betterWay: 'Dive into my',
    betterWayFinish: 'digital world !',
    description:
      'Passionate student and budding web developer, I create innovative digital solutions while pursuing my studies. Explore my journey, my projects, and join me on this digital adventure.',
    getStarted: 'Get started',
    skillsTitle: 'Skills and Knowledge',
    skillsDescription: 'As a student and entrepreneur, I have acquired a variety of skills in various technologies. I have had the opportunity to put them into practice in both academic projects and professional missions, which allows me to apply this knowledge concretely and offer solutions tailored to specific needs.',
    maintenanceTitle: 'Site in Development',
    maintenanceDescription: 'This site is currently under development. I am actively working to provide you with an optimal browsing experience.',
    downloadCVTitle: 'Download my Resume',
    downloadCVDescription: 'Click the button below to download my resume. I am available for any questions or to explore potential collaboration opportunities.',
    navigation: [
      { name: 'HOME', href: '#' },
      { name: 'CAREER', href: '#' },
      { name: 'RESOURCES', href: '#' },
    ],
  },
}

// Gestion du changement de langue
watch(selectedLanguage, (newLanguage) => {
  localStorage.setItem('language', newLanguage.code)
})

// Gestion de la barre de navigation selon le scroll
const mobileMenuOpen = ref(false)
const headerClasses = ref('bg-white dark:bg-gray-900')
const textClasses = ref('text-gray-900')

// Ouverture/fermeture des modales
const openModal = ref(false)
const openModalDownloadCV = ref(false)

function handleScroll() {
  const scrollPosition = window.scrollY

  if (scrollPosition > 0) {
    headerClasses.value = 'bg-white dark:bg-gray-900 shadow-md'
  } else {
    headerClasses.value = 'bg-white dark:bg-gray-900'
  }
}

function closeModal() {
  openModal.value = false

  // Vérifiez si la modale de téléchargement du CV est ouverte, sinon ouvrir la modale de maintenance après un délai.
  if (!openModalDownloadCV.value) {
    setTimeout(() => {
      openModal.value = true
    }, 3000)
  }
}

// Initialisation du thème et de la langue à partir du localStorage
onMounted(() => {
  // Charger le thème stocké ou définir le thème par défaut
  const savedTheme = localStorage.getItem('theme') || 'dark';
  theme.value = savedTheme;
  document.documentElement.classList.toggle('dark', theme.value === 'dark');
  textClasses.value = theme.value === 'dark' ? 'text-white' : 'text-gray-900';

  // Charger la langue stockée ou définir la langue par défaut
  const savedLanguage = localStorage.getItem('language') || 'fr';
  const language = languages.find((lang) => lang.code === savedLanguage);
  if (language) {
    selectedLanguage.value = language;
  }

  window.addEventListener('scroll', handleScroll);

  // Ouvre la modale après 3 secondes si aucune autre modale n'est ouverte
  if (!openModalDownloadCV.value) {
    setTimeout(() => {
      openModal.value = true;
    }, 3000);
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

const t = computed(() => messages[selectedLanguage.value.code])
const navigation = computed(() => t.value.navigation)

// Importation des autres logos existants
import pathLaravel from '@/assets/logotech/Laravel.svg';
import pathVueJS from '@/assets/logotech/Vue.js.svg';
import pathJavaScript from '@/assets/logotech/JavaScript.svg';
import pathHTML from '@/assets/logotech/HTML5.svg';
import pathCSS from '@/assets/logotech/CSS3.svg';
import pathBootstrap from '@/assets/logotech/Bootstrap.svg';
import pathTailwindCSS from '@/assets/logotech/TailwindCSS.svg';
import pathJava from '@/assets/logotech/Java.svg';
import pathPHP from '@/assets/logotech/PHP.svg';
import pathMySQL from '@/assets/logotech/MySQL.svg';
import pathApache from '@/assets/logotech/Apache.svg';
import pathCloudflare from '@/assets/logotech/Cloudflare.svg';
import pathWordPress from '@/assets/logotech/WordPress.svg';
import pathWooCommerce from '@/assets/logotech/WooCommerce.svg';
import pathChatGPT from '@/assets/logotech/chatgpt.png';
import pathGitHUB from '@/assets/logotech/GitHub.svg';
import pathGitLAB from '@/assets/logotech/GitLab.svg';

const people = [
  // Design
  {
    name: 'Tailwind CSS',
    role: 'Utility-First CSS Framework',
    imageUrl: pathTailwindCSS,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Bootstrap',
    role: 'CSS Framework',
    imageUrl: pathBootstrap,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'CSS3',
    role: 'Styling',
    imageUrl: pathCSS,
    xUrl: '#',
    linkedinUrl: '#',
  },
  // FrontEnd
  {
    name: 'Vue.js',
    role: 'FrontEnd Framework',
    imageUrl: pathVueJS,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'JavaScript',
    role: 'Programming Language',
    imageUrl: pathJavaScript,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'HTML5',
    role: 'Markup Language',
    imageUrl: pathHTML,
    xUrl: '#',
    linkedinUrl: '#',
  },
  // BackEnd
  {
    name: 'Laravel',
    role: 'BackEnd Framework',
    imageUrl: pathLaravel,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'PHP',
    role: 'BackEnd Language',
    imageUrl: pathPHP,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'MySQL',
    role: 'Database',
    imageUrl: pathMySQL,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Java',
    role: 'BackEnd Language',
    imageUrl: pathJava,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Apache',
    role: 'Web Server',
    imageUrl: pathApache,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Cloudflare',
    role: 'CDN & Security',
    imageUrl: pathCloudflare,
    xUrl: '#',
    linkedinUrl: '#',
  },
  // CMS (Content Management System)
  {
    name: 'WordPress',
    role: 'CMS Platform',
    imageUrl: pathWordPress,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'WooCommerce',
    role: 'E-commerce Platform',
    imageUrl: pathWooCommerce,
    xUrl: '#',
    linkedinUrl: '#',
  },
  // USING AI
  {
    name: 'ChatGPT',
    role: 'AI Chatbot',
    imageUrl: pathChatGPT,
    xUrl: '#',
    linkedinUrl: '#',
  },
  // GIT
  {
    name: 'GitHub',
    role: 'Code Hosting Platform',
    imageUrl: pathGitHUB,
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'GitLab',
    role: 'DevOps Platform',
    imageUrl: pathGitLAB,
    xUrl: '#',
    linkedinUrl: '#',
  },
];

</script>

<style scoped>
.text-gradient {
  background: linear-gradient(90deg, #fb7185, #fb7185, #f43f5e, #fb7185, #fb7185);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-2 {
  background: linear-gradient(90deg, #fb7185, #c084fc, #a855f7, #c084fc, #fb7185);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
